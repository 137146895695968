import React from 'react';

function Members() {
    return (
        <div className="text-center text-white">
            <div>
                <h6>
                    <a href="registermember">Register</a> to be a member of the Amra-Amra association
                </h6>
            </div>
            <div className='pt-5'>
                <h6>Members</h6>
            </div>
        </div>
    )
}
export default Members;