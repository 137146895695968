import React from 'react';
import logo from '../images/logo.png';

function Home() {
    return (
        <div className="text-center text-white">
            Home page
        </div>
    )
}
export default Home;